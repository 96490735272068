<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="举报人昵称" prop="nickname" >
        <a-input v-model="form.nickname" disabled/>
      </a-form-model-item>
      <a-form-model-item label="被举报人昵称" prop="reportNickname" >
        <a-input v-model="form.reportNickname" disabled />
      </a-form-model-item>
      <a-form-model-item label="帖子内容" prop="content" >
        <a-input v-model="form.content" disabled />
      </a-form-model-item>

      <div  v-if="!form.files">
        <a-form-model-item
          label="帖子图片"
          prop="files"
        >
        </a-form-model-item>
      </div>

      <div  v-if="form.files">
        <a-form-model-item
          label="帖子图片"
          prop="files"
          disabled
          :key="index"
        >
          <a-row :gutter="16">
            <a-col :span="4" v-for="(image, index) in form.files.split(',')" :key="index">
              <img :src="image" v-image-preview   alt="帖子图片" style="width: 100px; height: 100px; " />
            </a-col>
          </a-row>
        </a-form-model-item>
      </div>




<!--      <a-form-model-item label="举报类型" prop="reportType" disabled >-->
<!--      </a-form-model-item>-->


      <div  v-if="!form.reportImg">
        <a-form-model-item
          label="举报图片"
          prop="reportImg"
        >
        </a-form-model-item>
      </div>

      <div  v-if="form.reportImg">
        <a-form-model-item
          label="举报图片"
          prop="reportImg"
          disabled
          :key="index"
        >
          <a-row :gutter="16">
            <a-col :span="4" v-for="(image, index) in form.reportImg.split(',')" :key="index">
              <img :src="image" v-image-preview   alt="图片" style="width: 100px; height: 100px; " />
            </a-col>
          </a-row>
        </a-form-model-item>
      </div>




      <a-form-model-item label="举报内容" prop="reportContent" >
        <a-textarea v-model="form.reportContent" disabled/>
      </a-form-model-item>


      <a-form-model-item label="审核状态" prop="auditStatus" >
        <a-select v-model="form.auditStatus">
          <a-select-option :key="1">通过</a-select-option>
          <a-select-option :key="2">拒绝</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="form.auditStatus==2" label="审核反馈内容" prop="auditReason" >
        <a-input  v-model="form.auditReason" placeholder="请输入审核反馈内容" />
      </a-form-model-item>


      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getReport, addReport, updateReport } from '@/api/dynamic/report'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        reportUserId: null,

        dataType: null,

        dataId: null,

        reportType: null,

        reportImg: null,

        reportContent: null,

        auditStatus: 0,

        auditReason: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        reportUserId: null,
        dataType: null,
        dataId: null,
        reportType: null,
        reportImg: null,
        reportContent: null,
        auditStatus: 0,
        auditReason: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getReport({"id":id}).then(response => {
        this.form = response.data
        if (this.form.auditStatus == 0) {
          this.form.auditStatus = null
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateReport(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addReport(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
